import React, { useEffect, useState } from "react";

import { ReactComponent as PDGLogo } from "../logo.svg";

export const Navigation = (props) => {
  const { whiteBg } = props;
  const [isHidden, setIsHidden] = useState(false);

  const hideBar = () => {
    const pos = window.pageYOffset;
    pos > 120 ? setIsHidden(true) : setIsHidden(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", hideBar, { passive: true });

    return () => {
      window.removeEventListener("scroll", hideBar);
    };
  }, []);

  let classHide = isHidden && !whiteBg ? "menu-hide" : "";

  console.log(whiteBg);
  let classBg = whiteBg ? "menu-white" : "";

  return (
    <nav
      id="menu"
      className={`navbar navbar-default navbar-fixed-top ${classBg} ${classHide}`}
    >
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a
            className="navbar-brand page-scroll"
            href="#page-top"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {/* <PDGLogo style={{ height: 48, width: 48 }} /> */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              Ace PDG
              <span>mobile app</span>
            </div>
          </a>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="/#features" className="page-scroll">
                Home
              </a>
            </li>
            <li>
              <a href="/#about" className="page-scroll">
                About Us
              </a>
            </li>
            <li>
              <a href="/#top" className="page-scroll">
                Pricing
              </a>
            </li>
            {/* <li>
              <a href="#App" className="page-scroll">
                APP
              </a>
            </li> */}

            <li>
              <a href="/#contact" className="page-scroll">
                Contact Us
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
