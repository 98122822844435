import React from "react";
import Footer from "./footer";

import { Navigation } from "./navigation";

export const PrivacyPolicy = (props) => {
  return (
    <div>
      <Navigation whiteBg={true} />
      <div id="privacy" className="container">
        <div className="row">
          <div className="left-nav">
            <div className="row">
              <div className="left-nav-title">
                <a>Terms & Condition</a>
              </div>
              <div>
                <a>Terms of Use</a>
              </div>
              <div>
                <a>Acceptable Use Policy</a>
              </div>
              <div>
                <a>Copyright and Trademark Policy</a>
              </div>
              <div>
                <a>Payments and Refund Policy</a>
              </div>
              <div>
                <a>Honor Code</a>
              </div>
              <div className="left-nav-title">
                <a href="#privacy-notice">Privacy Policy</a>
              </div>
            </div>
          </div>
          <div id="privacy-notice" className="col-lg-9 col-md-offset-3">
            <h3>Privacy Notice </h3>
            <h5>Eﬀective: November 1, 2024.</h5>
            <div>
              {/* KEY INFORMATION */}
              <p>Key Information</p>

              <ul>
                <li>
                  Ace Military Apps LLC is the data controller of the Personal
                  Data we collect about you (i.e., the entity that determines
                  the means and purposes of collecting, using, and disclosing
                  the Personal Data (as defined below).
                </li>
                <li>
                  We collect the Personal Data set out in the "What Personal
                  Data We Collect" section of this Privacy Notice below,
                  including account registration details such as name and email,
                  details of Content Oﬀerings you undertake, survey information
                  (where you provide it), identity verification data, and
                  information about your use of our site and Services.
                </li>

                <li>
                  We use your Personal Data for the purposes set out in the "How
                  We Use Personal Data" section of this Privacy Notice below,
                  including providing our site and Services to you, ensuring the
                  security and performance of our site, conducting research
                  relating to Content Oﬀerings, sharing information with our
                  Content Providers and our suppliers, direct marketing, and
                  performing statistical analysis of the use of our site and
                  Services.
                </li>
                <li>
                  You have a number of rights that you can exercise in relation
                  to our use of your Personal Data, as set out in the "Your
                  Rights" section of this Privacy Notice below.
                </li>
              </ul>
              <p>Purpose and who we are</p>
              <p>
                The purpose of this Privacy Notice is to describe how Ace PDG,
                Inc., our subsidiaries, and our international branches, ("PDG
                Ready," "us," "we," or "our") collects, uses, and shares
                information about you through our online interfaces (e.g.,
                websites and mobile applications) owned and controlled by us,
                including but not limited to pdgready.com and
                readymilitaryapps.com (collectively referred to herein as the
                "Site"). Please read this Privacy Notice carefully to understand
                what we do. If you do not understand any aspects of our Privacy
                Notice, please feel free to contact us at{" "}
                <a href="mailto:acepdgapp@gmail.com">acepdgapp@gmail.com</a>.
                Your use of our Site is also governed by our Terms of Use. Terms
                used but not defined in this Privacy Notice can be found in our
                Terms of Use. Ace Military Apps LLC is a Wyoming corporation
                with a principal place of business at 312 W 2nd Street, Unit
                #A2833, Casper, WY 82601.
              </p>
              <p>What Information this Privacy Notice Covers</p>
              <p>
                This Privacy Notice covers information we collect from you
                through our Site. Our Site’s functionality can be used without
                revealing any Personal Data, but for features or Services
                related to the Content Oﬀerings, <b>Personal Data</b> may be
                required. In order to access certain features and benefits on
                our Site, you may need to submit, or we may collect, "Personal
                Data" (i.e., information that can be used to identify you and
                which may also be referred to as “personally identifiable
                information” or “personal information”). Personal Data can
                include information such as your name, email address, IP
                address, and device identifier, among other things. You are
                responsible for ensuring the accuracy of the Personal Data you
                submit to Ace PDG. Inaccurate information may aﬀect your ability
                to use the Site, the information you receive when using the
                Site, and our ability to contact you. For example, your email
                address should be kept current because that is one of the
                primary manners in which we communicate with you.
              </p>
              <p>Legal Bases for Collecting and Using Personal Data</p>
              <p>
                We consider that the legal bases for using your Personal Data as
                set out in this Privacy Notice are as follows:
              </p>
              <ul>
                <li>
                  to perform our obligations under any contract with you (for
                  example, to comply with the Terms of Use of our Site which you
                  accept by browsing our website and/or to comply with our
                  contract to provide Services to you, your academic
                  institution, your employer, or other organization that may be
                  providing you with access to our Services)
                </li>
                <li>to comply with our legal obligations;</li>
                <li>
                  for our legitimate interests or the legitimate interests of
                  others. Our legitimate interests are to:
                  <ul>
                    <li>run, grow and develop our business;</li>
                    <li>operate our Site and provide our Services;</li>
                    <li>
                      select appropriately skilled and qualified suppliers;
                    </li>
                    <li>build relationships with partners;</li>
                    <li>carry out research and statistical analysis;</li>
                    <li>carry out marketing and business development; and</li>
                    <li>for internal administrative and auditing purposes.</li>
                  </ul>
                </li>
                <li>
                  where you have given consent, for example, to send you certain
                  communications or where you submit certain information to us,
                  or where required to rely upon consent by local law.
                </li>
              </ul>

              <p>
                Which legal basis applies to a specific activity will depend on
                the type of personal information used and the context in which
                it is being used.
              </p>
              <p>
                If we rely on our (or another party’s) legitimate interests for
                using your personal information, we will undertake a balancing
                test to ensure that our (or the other party’s) legitimate
                interests are not outweighed by your interests or fundamental
                rights and freedoms which require protection of the personal
                information.
              </p>
              <p>
                We may process your personal information in some cases for
                marketing purposes on the basis of your consent (which you may
                withdraw at any time as described below).
              </p>
              <p>
                If we rely on your consent for us to use your personal
                information in a particular way, but you later change your mind,
                you may withdraw your consent by visiting your profile page and
                clicking the box to remove consent and we will stop doing so. If
                you withdraw your consent, this may impact the ability for us to
                provide some of our Services to you.
              </p>
              <p>What Personal Data We Collect</p>
              <p>
                We gather the following types of information about users through
                the Site:
              </p>
              <ol>
                <li>
                  <b>Information relating to your use of our Site.</b>When users
                  come to our Site, we may track, collect, and aggregate
                  information indicating, among other things, which pages of our
                  Site were visited, the order in which they were visited, when
                  they were visited, and which hyperlinks were clicked. We also
                  collect information from the URLs from which you linked to our
                  Site. Collecting such information may involve logging the IP
                  address, operating system, and browser software used by each
                  user of the Site. We may be able to determine from an IP
                  address a user’s Internet Service Provider and the geographic
                  location of their point of connectivity. Location data may be
                  used to route your traﬃc to the nearest regional server to
                  reduce latency and improve your experience. We may also use
                  cookies, web beacons, and other similar technologies when you
                  visit our Site.
                </li>
                <li>
                  <b>
                    Personal Data provided directly by you or via third parties.
                  </b>
                  We collect Personal Data that you provide to us when you
                  register for an account, update or change information for your
                  account, purchase products or Services, complete a survey,
                  sign-up for email updates, participate in our public forums,
                  send us email messages, and/or participate in Content Oﬀerings
                  or other Services on our Site. We may use the Personal Data
                  that you provide to respond to your questions, provide you the
                  specific Content Oﬀering and/or Services you select, send you
                  updates about Content Oﬀerings or other Ace PDG events, and
                  send you email messages about Site maintenance or updates.
                  <ul>
                    <li>
                      Personal Data provided directly by you or via third
                      parties.
                    </li>
                    <li>
                      Updates. Ace PDG may oﬀer you the ability to receive
                      updates either via email or by posting on portions of the
                      Site only accessible to registered users. In order to
                      subscribe to these Services, you may be required to
                      provide us with Personal Data such as your name and email
                      address.
                    </li>
                    <li>
                      Forums. Ace PDG may oﬀer public forums from time to time
                      (the "Forums") where you can share comments and thoughts.
                      In order to participate in the Forums, you may be required
                      to register with us and/or provide us with Personal Data
                      such as your name and email address.
                    </li>
                    <li>
                      Participation in Content Oﬀerings. Ace PDG oﬀers users the
                      opportunity to participate in Content Oﬀerings on or
                      through the Site. If you desire to participate in a
                      Content Oﬀering, you will be asked to provide us with
                      certain information necessary to conduct such a Content
                      Oﬀering. This information may include, among other things,
                      your name and email address.
                    </li>
                  </ul>
                </li>
              </ol>

              <p>
                Communications with Ace PDG. We may receive and record Personal
                Data when you send us an email message or otherwise contact us,
                including through the use of an in-platform messaging/chat
                feature.
              </p>
              <ul>
                <li>
                  Third Party Sites. We may receive Personal Data when you
                  access or log-in to a third party site, e.g., Facebook, from
                  our Sites. This may include text and/or images of your
                  Personal Data available from the third party site.
                </li>
                <li>
                  Surveys. We may receive Personal Data when you provide
                  information in response to a survey.
                </li>
                <li>
                  hird Party Payment Processing. Ace PDG provides you with the
                  ability to pay for Content Oﬀerings and other Services using a
                  credit card or other methods through a third party payment
                  processing service provider. Please note that our service
                  provider – not Ace PDG – collects and processes your financial
                  info.
                </li>
              </ul>
              <p>How We Use Personal Data</p>
              <p>
                Ace PDG collects and uses Personal Data for the purposes
                described in this section. Ace PDG may share such Personal Data
                for these purposes with third parties, such as service
                providers, industry partners, and other commercial partners.
              </p>
              <ul>
                <li>
                  {" "}
                  Providing the Site and our Services. We use Personal Data
                  which you provide to us in order to allow you to access and
                  use the Site and in order to provide any info, products, or
                  Services that you request from us.
                </li>
                <li>
                  Technical support and security. We may use Personal Data to
                  provide technical support to you, where required, and to
                  ensure the security of our Services and the Site.
                </li>
                <li>
                  Updates. We use Personal Data collected when you sign-up for
                  our various email or update services to send you the messages
                  in connection with the Site or Content Oﬀerings. We may also
                  archive this information and/or use it for future
                  communications with you, where we are legally entitled to do
                  so.
                </li>
                <li>
                  Analyzing and improving Site and Services. We use Personal
                  Data, and share such Personal Data with third- party service
                  providers, relating to your use of the Site and Services to
                  record and analyze how learners interact with the Site, its
                  features and content, and our Services. This analysis may
                  involve using techniques such as machine learning, or AI
                  tools. We use this info to better understand demand for Ace
                  PDG’s and Partners’ oﬀerings, inform Ace PDG Site and product
                  developments, and build higher quality, more useful Services.
                </li>
                <li>
                  Participation in Content Oﬀerings. We use the Personal Data
                  that we collect from you when you participate in a Content
                  Oﬀering through the Site for processing purposes, including
                  but not limited to tracking completion of the Content Oﬀerings
                  and results of Quizzes and Mock Exams. We may also use the
                  information generated when taking a Content Oﬀering or using
                  the Services for predictive analysis of your performance in
                  the Content Oﬀerings. Also, we may archive this information
                  and/or use it for future communications with you, where we are
                  legally entitled to do so.
                </li>
                <li>
                  Use of Interactive/Personalized Site Features. We use Personal
                  Data and share such Personal Data with third- party service
                  providers to facilitate and personalize your interaction with
                  Site features such as chatbots, automated translation and
                  grading functionality, tailored recommendations, and learner
                  engagement features. These may utilize machine learning and/or
                  AI tools. Where required or otherwise appropriate, you will be
                  provided with additional information, in advance, about
                  processing of your Personal Data via these features, including
                  the existence of any automated decision-making where relevant
                  (along with meaningful information about the logic involved,
                  as well as the significance and the envisaged consequences of
                  such processing).
                </li>
                <li>
                  Identity Verification. For Services that require identity
                  verification, we may use the Personal Data that we collect for
                  verifying your identity, and for authenticating that
                  submissions made on the Site were made by you. This service
                  may be provided through a third-party identity verification
                  vendor. Your photo identification document will be deleted
                  after successful verification of your profile information.
                </li>
                <li>
                  Communications with or from Ace PDG. When you send us an email
                  message, use in-platform messaging/chat features (which may
                  utilize technology provided by third party service providers
                  contracted by Ace PDG) or otherwise contact us, we and our
                  third-party service providers may record and use the
                  information provided by you to respond to your communication
                  and/or for other purposes described in this Privacy Notice or
                  in other privacy info provided to you during your interaction
                  with our communication systems. We and our third-party service
                  providers may also archive this information and/or use it for
                  future communications with you where we are legally entitled
                  to do so. Where we send you emails, we may track the way that
                  you interact with these emails (such as when you open or click
                  on a link inside an email). We use this information for the
                  purposes of optimizing and better tailoring our communications
                  to you.
                </li>
                <li>
                  Research. We may share general course data (including Quiz or
                  Mock Exam submissions and results), information about your
                  activity on our Site, and demographic data from surveys so
                  that we may use the data for research related to online
                  education.
                </li>
                <li>
                  Marketing and Advertising. We use Personal Data to send
                  marketing and serve advertising, to inform our marketing and
                  advertising strategies and content, to understand more about
                  our audiences and to personalize our marketing and
                  advertising, so that they are more relevant to particular
                  audiences. We may share Personal Data with the providers of
                  third party tools and services for some of these activities,
                  in particular for online advertising purposes, including
                  targeted advertising and custom audience activities, (for
                  example, we use advertising services from Meta, amongst
                  others).
                </li>
                <li>
                  Disclosure to Ace PDG Contractors. We use various third party
                  service providers, vendors, and contractors (collectively,
                  "Contractors") to support Ace PDG activities, including the
                  purposes listed in this section of the Privacy Notice. Our
                  Contractors will have access to Personal Data to the extent
                  necessary for provision of their services to Ace PDG. These
                  Contractors may include vendors and suppliers that provide us
                  with technology, services, and/or content related to the
                  operation and maintenance of the Site or the Content Oﬀerings,
                  or related to Ace PDG’s other business. Access to your
                  Personal Data by these Contractors is limited to information
                  reasonably necessary for the Contractor to perform its limited
                  function for us.
                </li>
                <li>
                  Government Authorities: Ace PDG may share your Personal Data
                  with various government authorities (such as regulatory
                  bodies, law enforcement activities, and national security
                  organizations) in response to lawful subpoenas, court orders,
                  or other legal processes; to establish or exercise our legal
                  rights or to protect our property; to defend against legal
                  claims; or as otherwise required by law. In such cases we
                  reserve the right to raise or waive any legal objection or
                  right available to us.
                </li>
                <li>
                  Legal Rights and Actions: Ace PDG may share your Personal Data
                  with government authorities, external advisors and other
                  relevant or aﬀected third parties when we believe it is
                  appropriate: to investigate, prevent, or take action regarding
                  illegal or suspected illegal activities; to protect and defend
                  the rights, property, or safety of Ace PDG, the Site, our
                  users, customers, or others; and in connection with our Terms
                  of Use and other agreements.
                </li>
                <li>
                  Disclosure to Acquirers. Ace PDG may disclose and/or transfer
                  your Personal Data to an acquirer, assignee or other successor
                  entity in connection with a sale, merger, or reorganization of
                  all or substantially all of the equity, business, or assets of
                  Ace PDG to which your Personal Data relates.
                </li>
                <li>
                  e-Readers. If we receive any Personal Data related to the
                  extent to which you use designated e-Readers or other devices
                  to access Ace PDG materials, we may archive it, and use it for
                  research, business, or other purposes.
                </li>
              </ul>
              <p>External Links</p>
              <p>
                For your convenience we may provide links to sites operated by
                organizations other than Ace PDG ("Third Party Sites") that we
                believe may be of interest to you. We do not disclose your
                Personal Data to these Third Party Sites unless we have a lawful
                basis on which to do so. We do not endorse and are not
                responsible for the privacy practices of these Third Party
                Sites. If you choose to click on a link to one of these Third
                Party Sites, you should review the privacy policy posted on the
                other site to understand how that Third Party Site collects and
                uses your Personal Data.
              </p>
              <p>Retention of Personal Data</p>
              <p>
                We keep your Personal Data for no longer than necessary for the
                purposes for which the Personal Data is collected and processed.
                The length of time we retain Personal Data for depends on the
                purposes for which we collect and use it and/or as required to
                comply with applicable laws and to establish, exercise, or
                defend our legal rights.
              </p>
              <p>Confidentiality & Security of Personal Data</p>
              <p>
                We will use industry standard physical, technical, and
                administrative security measures to keep your Personal Data
                confidential and secure, and will not share it with third
                parties, except as otherwise provided in this Privacy Notice, or
                unless such disclosure is necessary in special cases, such as a
                physical threat to you or others, as permitted by applicable
                law. Because the Internet is not a 100% secure environment, we
                cannot guarantee the security of Personal Data, and there is
                some risk that an unauthorized third party may find a way to
                circumvent our security systems or that transmission of your
                information over the Internet will be intercepted. It is your
                responsibility to protect the security of your login
                information. Please note that e-mail communications are
                typically not encrypted and should not be considered secure.
              </p>
              <p>Your Rights</p>
              <p>
                You have certain rights in relation to your Personal Data. You
                can access your Personal Data and confirm that it remains
                correct and up-to-date, choose whether or not you wish to
                receive material from us or some of our partners, and request
                that we delete or provide you with a copy of your Personal Data
                by emailing{" "}
                <a href="mailto:acepdgapp@gmail.com">acepdgapp@gmail.com</a>.
              </p>
              <p>
                We try to answer every request promptly where possible, and
                provide our response within the time period stated by applicable
                law. Keep in mind, however, that when deleting your Personal
                Data there may be residual information that will remain within
                our databases, access logs, and other records, which may or may
                not contain your Personal Data. Please also note that certain
                Personal Data may be exempt from such requests in certain
                circumstances, which may include if we need to retain your
                Personal Data to comply with a legal obligation. When you email
                us with a request, we may ask that you provide us with
                information necessary to confirm your identity.
              </p>
              <p>Questions, Suggestions, and Complaints</p>
              <p>
                If you have any privacy-related questions, suggestions,
                unresolved problems, or complaints, you may contact us via
                acepdgapp@gmail.com.
              </p>
              <p>U.S. State Privacy Rights</p>
              <p>Shine the Light</p>
              <p>
                Shine the Light Under California’s "Shine the Light" law,
                California residents who provide personal information in
                obtaining products or services for personal, family, or
                household use are entitled to request and obtain from us, once a
                calendar year, information about the customer information we
                shared, if any, with other businesses for their own direct
                marketing uses. If applicable, this information would include
                the categories of customer information and the names and
                addresses of those businesses with which we shared customer
                information for the immediately prior calendar year (e.g.
                requests made in 2025 will receive information regarding 2024
                sharing activities).
              </p>
              <p>
                To obtain this information, please send an email message to
                acepdgapp@gmail.com with "Request for California Privacy
                Information" on the subject line and in the body of your
                message. We will provide the requested information to you at
                your email address in response. Please be aware that not all
                information sharing is covered by the "Shine the Light"
                requirements and only information on covered sharing will be
                included in our response.
              </p>
              <p>
                To obtain this information, please send an email message to
                acepdgapp@gmail.com with "Request for California Privacy
                Information" on the subject line and in the body of your
                message. We will provide the requested information to you at
                your email address in response. Please be aware that not all
                information sharing is covered by the "Shine the Light"
                requirements and only information on covered sharing will be
                included in our response.
              </p>
              <p>U.S. State Privacy Laws</p>
              <p>
                Under privacy laws of certain U.S. States (“U.S. State Privacy
                Laws”), residents of relevant U.S. States have the right to know
                what personal information about them is collected and how this
                personal information is used and disclosed. Residents of
                relevant U.S. States can also exercise certain rights in respect
                of their data (such as access, deletion, and opting in/out of
                certain disclosures and uses).
              </p>
              <p>International Privacy Practices</p>
              <p>
                Our Site is primarily operated and managed on servers located
                and operated within the United States. In order to provide our
                products and Services to you, you may be sending your Personal
                Data outside of the country where you reside or are located,
                including to the United States. Accordingly, your Personal Data
                may be transferred outside of the country in which you reside or
                are located, including to countries that may not provide the
                same level of protection for your Personal Data. We are
                committed to protecting Personal Data when it is transferred. If
                you reside or are located within the EEA, United Kingdom,
                Switzerland (or other region that oﬀers similar protections) and
                such data transfers occur, we take appropriate steps to ensure
                an equivalent level of protection for your Personal Data. We
                participate in and commit to adhering to the EU-U.S. Data
                Privacy Framework, the UK extension to the EU-U.S. Data Privacy
                Framework, or the Swiss-U.S. Data Privacy Framework when
                transferring data from the EEA, UK or Switzerland to the United
                States. Please see our Data Privacy Framework Notice, below, for
                further information.
              </p>
              <p>Changing Our Privacy Notice</p>
              <p>
                Please note that we review our privacy practices from time to
                time, and that these practices are subject to change. Any
                change, update, or modification will be eﬀective immediately
                upon posting on our Site. We will notify you of any material
                change to this Privacy Notice by posting a notice on our Site’s
                homepage for a reasonable period of time following such update
                or by sending an email to the email address associated with your
                user account, and by changing the eﬀective date (located at the
                top and bottom of this page). Be sure to return to this page
                periodically to ensure familiarity with the most current version
                of this Privacy Notice.
              </p>
              <p>No Information from Children Under 13</p>
              <p>
                Ace PDG strongly believes in protecting the privacy of children.
                Any use or access by anyone under the age of 13 is prohibited,
                and certain regions and Content Oﬀerings may have additional
                requirements and/or restrictions. In line with this belief, we
                do not knowingly collect or maintain Personal Data on our Site
                from persons under 13 years of age, and no part of our Site is
                directed to persons under 13 years of age. If you are under 13
                years of age, then please do not use or access this Site at any
                time or in any manner. We will take appropriate steps to delete
                any Personal Data of persons less than 13 years of age that has
                been collected on our Site without verified parental consent
                upon learning of the existence of such Personal Data.
              </p>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  );
};
