import React, { useEffect } from "react";

import { ReactComponent as AppstoreLogo } from "../assets/img/download/appstore.svg";
import { ReactComponent as PlaystoreLogo } from "../assets/img/download/playstore.svg";
import { useState } from "react";
import { useParallax } from "react-scroll-parallax";
import { useMediaQuery } from "react-responsive";

export const Header = (props) => {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  const parallax = useParallax({
    onProgressChange: (progress) => {
      if (parallax.ref.current) {
        // set progress to CSS variable
        parallax.ref.current.style.setProperty(
          "--progress",
          progress.toString()
        );
      }
    },
  });
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h1>
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h1>
                {props.data?.subtitle && (
                  <p className="subtitle">{props.data.subtitle}</p>
                )}
                <p>{props.data ? props.data.paragraph : "Loading"}</p>
                <div className="col store-container">
                  <div
                    className="row"
                    style={{
                      justifyContent: "space-evenly",
                      display: "flex",
                      alignItems: "center",
                      maxHeight: 72,
                    }}
                  >
                    <a href="#" alt="">
                      <AppstoreLogo width={144} />
                    </a>
                    <a href="#" alt="">
                      <PlaystoreLogo width={144} />
                    </a>
                  </div>

                  <p>Available for iOS 8 and Android Devices From 5.5</p>
                </div>
              </div>
            </div>
            <div className="container-float">
              <img
                ref={parallax.ref}
                src={"img/intro-ss.png"}
                className="img-float"
                style={{
                  width: `calc(${isMobile ? 200 : 100}vw * var(--progress))`,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
