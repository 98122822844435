import React from "react";

export const About = (props) => {
  return (
    <div>
      <div id="about-images">
        <div className="container about-images-container">
          <div className="col-lg-6 col-md-6 col-xs-12 row about-mock-exam float-left">
            <div className="col-lg-6 col-md-6 col-xs-12">
              <h2>
                Mock Exam<br></br>Mode
              </h2>
              <p>
                Feeling ready? Take the closest to actual PFE on the market! 60
                questions are sourced from AFH1 and another 20 from AFH 36-2647
                (SJT section). Each question is 1.25 points, totaling 100.
              </p>
              <p>
                Take the mock exam as often as you need to feel ready. Each exam
                features new questions!
              </p>
            </div>
            <div className="mock-exam-img">
              <img src={"img/screenshots/mock-exam.png"} className="" />
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-xs-12 row about-leaderboard float-right">
            <div className="col-lg-6 col-md-6 col-xs-12">
              <h2>Ace PDG Leaderboard</h2>
              <p>
                Curious how ready you are compared to other Airmen nationwide?
                Check out the Ace PDG leaderboard once you finish a mock exam.
              </p>
              <p>
                E-5 and E-6 promotions are awarded based on how well you do
                compared to peers.
              </p>
              <p>Visit the leaderboard to find out.</p>
            </div>
            <div className="leaderboard-img">
              <img src={"img/screenshots/leaderboard.png"} />
            </div>
          </div>
        </div>
      </div>
      <div id="about">
        <div className="container about-details-container">
          <div className="row">
            {/* <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/about.jpg" className="img-responsive" alt="" />{" "}
          </div> */}
            <div className="col-lg-6 col-md-6 col-xs-12">
              <div className="about-text">
                {/* <h2>About Us</h2> */}

                <h5>Why Choose Us?</h5>
                <p className="about-text-info">
                  Use the Ace PDG mobile app to get promoted to E5 or E6,
                  advance your career and earn more money!
                </p>
                <div className="col about-item-container">
                  {props.data
                    ? props.data.Why.map((d, i) => <li>{d}</li>)
                    : "loading"}
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-xs-12 choose-container">
              <img
                src={"img/choose-image.png"}
                className="choose-container-image float-right"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
